<template>
  <div class="position-relative hold-links">
    <iq-card>
      <div>
        <ul class="profile-list">
          <router-link
            tag="li"
            class="profile-list-options"
            v-for="(item, i) in listMenuItems"
            :key="i"
            :to="{ name: item.pathName }"
          >
            <div class="d-flex align-items-center list-item">
              <span
                v-if="item.icon"
                v-html="item.icon"
                class="icon-profile"
              ></span>
              <span class="link list-name-profile">{{ $t(item.name) }}</span>
            </div>
          </router-link>
        </ul>
        <div class="">
          <b-button v-if="isOpen" class="hold-logout-btn" @click="logout">
            <img src="@/assets/images/icons/logout-icon.svg" />
            <span class="btn-logOut">{{ $t("auth.logout") }}</span>
          </b-button>
        </div>
      </div>
    </iq-card>
  </div>
</template>

<script>
export default {
  name: "profileSidebar",
  data() {
    return {
      d: new Date(),
      // userInfo: [],
      userData: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).user.name
        : "",
      userImg: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).user.avatar
        : "",
      isOpen: true,
      listMenuItems: [
        {
          name: "endUser.userProfile",
          pathName: "userSettings",
          icon: '<i class="las la-id-card"></i>',
        },
        {
          name: "userProfile.wallet",
          pathName: "indexWallet",
          icon: '<i class="las la-wallet"></i>',
        },
        {
          name: "userProfile.marketplace",
          pathName: "user-marketplace",
          icon: '<i class="las la-shopping-cart"></i>',
        },
        {
          name: "payment.myAddress",
          pathName: "addresses",
          icon: '<i class="las la-map-marker-alt"></i>',
        },
        {
          name: "userProfile.favourite",
          pathName: "favourite",
          icon: '<i class="lar la-heart"></i>',
        },
      ],
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userToken");
      this.$store.commit("updateUserInfo", null);
      this.$router.push({ name: "home" });
      this.$store.commit("cart/SET_CART", []);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.iq-card {
  border-radius: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
}

.w-90 {
  width: 90% !important;
}

.top-profile-info {
  width: 400px;
}

.font-size-27 {
  font-size: 27px !important;
}

.text-check {
  border-radius: 10px;
  padding: 3px;
  background: #ffff;
  color: red;
}

.profile-btn-active-icon {
  width: 12px !important;
  height: 12px !important;
}

/*.profile-toggle-btn-open{*/
/*top: 130px;*/
/*left: -13px;*/
/*  transition: all 0.7s ease-in-out;*/
/*  position: absolute;*/
/*}*/
/*.profile-toggle-btn-close{*/
/*  top: 130px;*/
/*  left: -13px;*/
/*  transition: all 0.7s ease-in-out;*/
/*  position: absolute;*/
/*  transform: rotate(180deg);*/
/*}*/
/*.user-cover{*/
/*  width: 76px;*/
/*  height: 76px;*/
/*  border: 0.5px solid #D39D45;*/
/*  border-radius: 10px;*/
/*  margin-right: 15px*/
/*}*/
.user-cover img {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.profile-list {
  margin: 0 !important;
  padding: 0;

  .list-item {
    padding: 1.2rem;
    background: #ffffff;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    .icon-profile {
      font-size: 1.5rem !important;
      padding: 0 0 0 0.5rem;
      &:lang(en) {
        padding: 0 0.5rem 0 0;
      }
    }

    .list-name-profile {
      font-size: 1.2rem;
      color: #000;
      font-weight: 500;
    }

    &:hover {
      background: #e4eae9;
    }
  }
}

.profile-list-options {
  padding: 0 !important;
  margin-bottom: 0.3rem;
}

/*.profile-list-options svg path {*/
/*  fill: var(--iq-secondary-dark);*/
/*}*/
/*.profile-list-options{*/

/*  padding-top: 10px;*/
/*  padding-bottom: 10px;*/
/*  padding-right: 36px;*/
/*}*/
/*.profile-list-options:hover{*/
/*  cursor: pointer;*/
/*  background-color: #D39D451D ;*/
/*  color: #D39D45 !important;*/
/*  transition: all 0.4s ease-in-out;*/
/*}*/
/*.profile-list-options:hover svg path{*/
/*  fill: #D39D45 !important;*/
/*}*/
/*.profile-list-options:hover svg, .profile-list-options:hover svg path{*/
/*  fill: #D39D45 !important;*/
/*}*/
/*.profile-list .router-link-exact-active svg {*/
/*  cursor: pointer;*/
/*  background-color: #D39D451D ;*/
/*  color: #D39D45 !important;*/
/*  transition: all 0.4s ease-in-out;*/
/*}*/
/*.profile-list .router-link-active , .profile-list .router-link-active svg path{*/
/*  cursor: pointer;*/
/*  background-color: #D39D451D ;*/
/*  color: #D39D45 !important;*/
/*  transition: all 0.4s ease-in-out;*/
/*  fill: #D39D45 !important;*/
/*  font-weight: bold;*/
/*}*/
.width-animation-open {
  transition: all 0.7s ease-in-out;
  width: 385px;
}

.width-animation-close {
  transition: all 0.7s ease-in-out;
  width: 109px;
}

.over-hidden {
  overflow: hidden;
}
.logout-icon {
  width: 29px;
  height: 29px;
  @media (max-width: 1200px) {
    width: 23px;
    height: 23px;
  }
}
.btn-logOut {
  color: #000 !important;
  padding-right: 0 !important;
  font-size: 1rem;
  display: inline-block;
  margin: 0 0.5rem;
}
.hold-logout-btn {
  margin-top: 1.8rem !important;
  padding: 0.7rem 0 !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff !important;
  border-radius: 2px !important;
  border: none !important;
  transition: all 0.3s ease-in-out;
  img {
    width: 18px;
    height: 18px;
    &:lang(en) {
      transform: scaleX(-1);
    }
  }
  &:hover {
    background: rgba(211, 34, 34, 0.21) !important;
  }
}
.profile-list-options.router-link-exact-active.router-link-active {
  .list-item {
    background: #e4eae9 !important;
    border-right: 4px solid #00443d;
    .list-name-profile {
      color: #00443d !important;
      font-weight: 700 !important;
    }
    &:lang(en) {
      border-right: none;
      border-left: 4px solid #00443d;
    }
  }
}
</style>
