<template>
  <b-container class="user-profile-page">
    <b-row>
      <div class="col-lg-3">
        <profile-sidebar class="toggle-main-profile"></profile-sidebar>
      </div>
      <div class="col-lg-9">
        <transition  name="router-anim" :enter-active-class="`animated fadeInUp`" mode="out-in" :leave-active-class="`animated fadeOut`">
          <router-view></router-view>
        </transition>
      </div>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import profileSidebar from '@/modules/userProfile/components/profileSidebar'
export default {
  name: 'userProfile',
  components: { profileSidebar },
  mounted () {
    core.index()
  }
}
</script>
<style lang="scss" scoped>
.user-profile-page{
  margin-top: 4.8rem;
}
.toggle-main-profile{
  margin: 0 !important;
}

</style>
